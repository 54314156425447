/* Estilos para o componente Main */
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 768px) {
  /* Outros estilos para telas menores */
  
  .mobile-info {
    display: flex;
  }
}

.main {
  height: 100vh; /* 100% da altura da janela */
  width: 100vw; /* 100% da largura da janela */
  position: relative; /* Garante que o conteúdo fique dentro do .main */
}

  
  .video-container {
    position: relative;
    width: 100%;
    height: 10vh; /* Defina a altura da viewport */
  }
  
  .video-bg {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  @media screen and (min-width: 768px) {
    .h1 {
      font-size: 36px; /* Tamanho do texto h1 em desktops */
    }
  
    .p {
      font-size: 20px; /* Tamanho do texto p em desktops */
    }
  }