/* Estilo do container principal */
.layout-container {
  display: flex; /* Ativar o modelo flexbox */
  flex-direction: column; /* Dispor os elementos verticalmente */
  justify-content: center; /* Alinhar verticalmente no centro */
  align-items: center; /* Alinhar horizontalmente no centro */
  height: 100vh; /* Altura igual à altura da viewport */
  background-color: #f6ebda00; /* Cor de fundo (ajuste conforme necessário) */
  padding: 10px; /* Espaçamento interno */
}

/* Estilo do título */
.c-2-title {
  font-size: 24px; /* Tamanho da fonte do título (ajuste conforme necessário) */
  margin-top: 15px;
  padding: 10px 15px;
    font-weight: bold;
    color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra de texto */
}

/* Estilo do texto */
.c-2 {
  font-size: 16px; /* Tamanho da fonte do texto (ajuste conforme necessário) */
  text-align: left; /* Alinhar o texto no centro */
  padding: 10px 15px; /* Espaçamento superior (ajuste conforme necessário) */
  color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra de texto */
}

/* Estilo do espaço lateral */


/* Estilo do iframe para dispositivos menores que 769px */
@media screen and (max-width: 768px) {
  iframe {
    width: 100%; /* Largura total do iframe */
    max-width: 100%; /* Largura máxima do iframe (ajuste conforme necessário) */
    height: 563px; /* Altura do iframe (ajuste conforme necessário) */
    border: none; /* Remover a borda do iframe */
    border-radius: 5px; /* Borda arredondada (pode ajustar conforme necessário) */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6); /* Sombra (pode ajustar conforme necessário) */
    margin-top: 0px; /* Espaçamento superior (ajuste conforme necessário) */
    border: 2px solid #ffd700;
  }
}

/* Estilo do iframe para dispositivos maiores que 769px */
@media screen and (min-width: 769px) {
  iframe {
    width: 1000px; /* Largura total do iframe */
     /* Largura máxima do iframe (ajuste conforme necessário) */
    height: 600px; /* Altura do iframe (ajuste conforme necessário) */
    border: none; /* Remover a borda do iframe */
    border-radius: 5px; /* Borda arredondada (pode ajustar conforme necessário) */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Sombra (pode ajustar conforme necessário) */
    margin-top: 20px; /* Espaçamento superior (ajuste conforme necessário) */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6); /* Sombra (pode ajustar conforme necessário) */
    border: 2px solid #ffd700;
  }
}
