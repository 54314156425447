.footer {
  background-color: #f6ebda;
  color: rgb(2, 2, 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-top: 15%;
  text-align: center;
}


.nav-li {
  list-style: none;
  padding: 5px;
  display: flex;
  font-size: 15px;
  justify-content: center;
  margin: 10px 0;
  border-bottom: 1px solid #008000;
  border-top: 1px solid #008000;
  border-left: 1px solid #008000;
  border-right: 1px solid #008000;
  border-radius: 5px;
}

.nav-li li {
  margin: 0 15px;
}

.nav-li a {
  color: rgb(7, 7, 7);
  text-decoration:none;
  text-transform: uppercase;
  font-weight:bold;
  color: #008000;
  transition: color 0.3s; 
  text-shadow: 0.5px 0.5px 0.8px rgba(0, 0, 0, 0.6);
}

/* Change color on hover to the same golden color */
.nav-li a:hover {
  color: #ffd700;
}

/* Define a animação para o pulso */
@keyframes pulse {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}


.logo-1 img {
  width: 50px; /* Tamanho da logo em mobile */
  height: 50px;
  margin: 20px 0;
  border: solid 2px #0dcd0d;
  border-radius: 50%;
  
}



@media screen and (min-width: 769px)  { 

  .footer {
    background-color: #f6ebda;
    color: rgb(2, 2, 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-top: inherit;
    text-align: center;
  }
  .nav-li {
    font-size: 22px;
  }
}

