.main {
    display: flex;
    flex-direction: column;
  }

  .new-cont {
    text-align: center;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 100px; /* Adjust vertical and horizontal padding */
  }
  
  
  .video-container {
    position: relative;
    width: 100%;
    height: 10vh; /* Defina a altura da viewport */
    overflow:scroll;
  }
  
  .video-bg {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  
  
  .image-bl {
    border-radius: 0px;
    text-align: center;
    flex: 0 0 calc(33.33% - 20px);
  }
  
  
  
  .c-1 {
    margin-top: 20px; /* Increase margin-top for more spacing from the edge */
    margin-bottom: 20px; /* Increase margin-bottom for more spacing from the edge */
    font-size: 24px;
    color: antiquewhite;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra de texto */
  }

  .c-1-title {
    margin-top: 15px;
    font-size: 28px ;
    font-weight: bold;
    color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra de texto */
  }

  
  @media screen and (max-width: 768px) {

    
    .carousel {
     -ms-overflow-style: none; /* IE 10+ */
      scrollbar-width: none; /* Firefox */
      overflow-x: scroll;
      display:flex;
    }
    .carousel::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    
    .carousel-item {
      flex: 0 0 350px;
      height: 500px;
      scroll-snap-align: start;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
    }

    .video-bg {
      position:fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1 !important;
    }
    
    .carousel-item img {
      max-width: 90%;
      max-height: 100%;
      border-radius: 5px;  
      margin-left: 25px;
      border: 2px solid transparent;
      background: linear-gradient(to bottom, #ffd700, white);
    }
    
    .text {
      background: linear-gradient(to right, #ffd700, #c0c0c0);
      -webkit-background-clip: text;
      color: transparent;
      margin-left: 25px;
    }
  
    .description {
      overflow: hidden;
      white-space: break-spaces;
      margin-left: 25px;
      margin-top: -10px;
      color: white;
      font-size: medium;
      animation: stretchTextDesktop 2s infinite alternate;
    }
  

    .c-1-title{
      font-size: 20px;
    }

    .c-1 {
      margin-top: 10px;
      font-size: 16px;
      margin-bottom: 25px;
      color: antiquewhite;
    }

    .new-cont {
      display: block; /* Altera para exibição em bloco */
      padding: 20px; /* Reduz o espaçamento interno no mobile */
    }
  
    .image-bl {
      margin-bottom: 20px; /* Adiciona espaçamento entre os blocos */
      margin-top: 90px;
      padding: 10px;
    }

    .image-bla {
      margin-bottom: 20px; /* Adiciona espaçamento entre os blocos */
      margin-top: 60px;
      padding: 10px;
    }
    
    .whatsapp-logo {
      width: 15px; /* Adjust width as needed */
      height: 15px; /* Adjust height as needed */
      margin-left: 5px; /* Add some spacing between text and logo */
    }

    .image-bl img {
      max-height: 220px;
      max-width: 90%;
      border-radius: 200px;
      transition: transform 0.3s ease;
      border: 2px solid transparent;
      background: linear-gradient(to bottom, #ffd700, white);
    }

}

  @media screen and (min-width: 769px) {
    .carousel {
      -ms-overflow-style: none; /* IE 10+ */
       scrollbar-width: none; /* Firefox */
       overflow-x: scroll;
       display:flex;
     }
     .carousel::-webkit-scrollbar {
       display: none; /* Safari and Chrome */
     }
     
     .carousel-item {
       flex: 0 0 500px;
       height: 500px;
       scroll-snap-align: start;
       scroll-snap-type: x mandatory;
       scroll-behavior: smooth;
     }
 
     .video-bg {
       position:fixed;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       object-fit: cover;
       z-index: -50 !important;
     }
     
     .carousel-item img {
       max-width: 90%;
       max-height: 100%;
       border-radius: 5px;  
       margin-left: 25px;
       border: 2px solid transparent;
       background: linear-gradient(to bottom, #ffd700, white);
     }
     
     .text {
       background: linear-gradient(to right, #ffd700, #c0c0c0);
       -webkit-background-clip: text;
       color: transparent;
       margin-left: 25px;
     }
   
     .description {
       overflow: hidden;
       white-space: break-spaces;
       margin-left: 25px;
       margin-top: -10px;
       color: white;
       font-size: medium;
       animation: stretchTextDesktop 2s infinite alternate;
     }
   
 
     .c-1-title{
       font-size: 20px;
     }
 
     .new-cont {
       display: block; /* Altera para exibição em bloco */
       padding: 20px; /* Reduz o espaçamento interno no mobile */
     }
   
     .image-bl {
       margin-bottom: 20px; /* Adiciona espaçamento entre os blocos */
       margin-top: 90px;
       padding: 10px;
     }
 
     .image-bla {
       margin-bottom: 20px; /* Adiciona espaçamento entre os blocos */
       margin-top: 60px;
       padding: 10px;
     }
     
     .whatsapp-logo {
       width: 15px; /* Adjust width as needed */
       height: 15px; /* Adjust height as needed */
       margin-left: 5px; /* Add some spacing between text and logo */
     }
 
    .c-1 {
      margin-top: 40px; /* Increase margin-top for more spacing */
      margin-bottom: 40px; /* Increase margin-bottom for more spacing */
      font-size: 24px;
      margin-right: 15%;
      margin-left: 15%;
      color: antiquewhite;
    }

    .image-bl img {
      max-height: 300px;
      max-width: 90%;
      border-radius: 200px;
      transition: transform 0.3s ease;
      border: 2px solid transparent;
      background: linear-gradient(to bottom, #ffd700, white);
    }
  
    .new-cont {
      padding: 90px 90px; /* Adjust padding for desktop */
    }

    
  }

  