@media screen and (min-width: 769px) {
.content {
  margin-top: 60px;
  text-align: center;
  display:row;
  justify-content:center;
  align-items: flex-start;
  padding: 50px;
  }
  
  .h1 {
    color: #ffd700;
    font-size: 38px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra de texto */
  }
  
  .p {
    color: antiquewhite;
    font-size: 26px;
    font-weight: 500;
    margin-left: 13%;
    margin-right: 13%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra de texto */
  }
} 

@media screen and (max-width: 768px) {
  .content {
    margin-top: 25px;
    text-align: center;
    display:row;
    justify-content:center;
    align-items: flex-start;
    padding: 50px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra de texto */
    }
    
    .h1 {
      color: #ffd700;
      font-size: 26px;
    }
    
    .p {
      color: antiquewhite;
      font-size: 16px;
      font-weight: 500;
    }
}