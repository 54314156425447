@media screen and (max-width: 768px) {
    .blue-text {
        color: rgb(255, 97, 12); /* Defina a cor desejada, por exemplo, azul */
        transition: color 0.3s ease; /* Adiciona uma transição suave de cor */
        
    }

     .foto {
        max-height: 70vh; /* Adjust the value as per your requirements */
        max-width: 100%;
        overflow: hidden; /* Hide any content that overflows the defined area */
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6);
    }
    
    .tilt-ct{
        justify-content: center;
        align-items: center;
        display: row;
        align-items: center;
    }

    .blue-text:hover {
    color: #095e09; /* Define a cor verde desejada */
    }
    
    .contact-card {
        background-color:  #1d1c1a69;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7);
        text-align: center;
        margin-top: 60px;
        width: 400px;
        height: auto;
        border: 1px dashed  #ffd700;
    }
    
    .contact-card h2 {
        font-size: 1.5rem;
        color: #ffd700;
        text-transform: uppercase;
        margin-bottom: 10px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    }
    
    .contact-card p {
        font-size: 1rem;
        color: #fffefe;
        margin-bottom: 15px;
    }
    
}     

@media screen and (min-width: 769px) {

.map {
    border-radius: 10px;
    border: 1px solid #ffd700;
    height: 90%;
    width: auto;
    margin-bottom: 20px;
}

.tilt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 80px;
}

.blue-text {
    color: rgb(255, 89, 0); /* Defina a cor desejada, por exemplo, azul */
    transition: color 0.3s ease; /* Adiciona uma transição suave de cor */
}

.blue-text:hover {
color: #989d08; /* Define a cor verde desejada */
}

.contact-card {
    /* Estilos gerais do cartão */
    background-color:  #12121287;
    padding: 20px;;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 800px; 
    border: 2px dashed #ffd700;
    height: auto;
    margin: 0 auto; /* Centralize horizontalmente */
  
}
    .contact-card h2 {
        /* Estilos do título */
        font-size: 2.6rem;
        text-transform: uppercase;
        color: #f8d80a;
        margin-bottom: 10px;
    }
      

    .contact-card p {
        /* Estilos do parágrafo de texto */
        font-size: 1.3rem;
        color: #fcf8f8;
        margin-bottom: 15px;
    }
   
    
}   